<!-- Main navigation fixed to bottom of page -->

<template>
  <sm-navigation-drawer
    class="side-nav elevation-3 bg-primary"
    v-if="user"
    v-model="visible"
    height="100vh"
    :style="'background-image: url(\''+backgroundUrl+'\'); background-repeat: no-repeat; background-size: cover;'"
    fixed
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    :touchless="$vuetify.breakpoint.mdAndUp"
    :touchless-open="showBackButton">
    <v-container fill-height px-2 class="menu-container" :class="{ 'mobile': $vuetify.breakpoint.smAndDown }">
      <v-layout justify-space-between column fill-height>
        <v-spacer class="top-spacer"></v-spacer>
        <v-flex shrink v-for="(item, index) in menuItems" :key="index" class="menu-item">
          <router-link tag="div" :to="item.path" class="d-inline"
                       @click.native="onClosePopover(visibleCallout && visibleCallout.target === item.id ? `popover-${visibleCallout.identifier}` : null)">
            <template v-if="item.id ==='community'">
              <v-badge right :color="showCommunityBadge?'':'transparent'" class="badge-small mr-2">
                <template v-slot:badge>
                  <span></span>
                </template>
                <v-icon style="height:25px;width:25px;">{{ item.icon }}</v-icon>
              </v-badge>
            </template>
            <template v-else>
              <v-icon style="height:25px;width:25px;" class="mr-2">{{ item.icon }}</v-icon>
            </template>
            <span style="vertical-align:top;">
              {{ item.path === '/app/profile' ? user ? user.firstName : item.label : item.label }}
            </span>
          </router-link>

          <badge-popover :key="visibleCallout ? visibleCallout.identifier : ''"
                         v-if="visibleCallout && visibleCallout.target === item.id"
                         :ref="`popover-${visibleCallout.identifier}`"
                         :id="visibleCallout.identifier"
                         :handle="visibleCallout.handle"
                         :title="visibleCallout.title"
                         :content="visibleCallout.content"></badge-popover>
        </v-flex>

        <v-spacer></v-spacer>

        <v-flex shrink class="menu-item" @click="logout" v-if="$vuetify.breakpoint.mdAndUp">
          <v-icon style="height:25px;width:25px;" class="mr-2">$vuetify.icons.starling_logout</v-icon>
          <span>{{ $t('common.actions.logout') }}</span>
        </v-flex>

        <v-flex v-if="partner && partner.type === 'COLABELLED'" shrink text-center class="powered-by">
          <p class="starling-italic-text">{{$t('common.partnership.powered_by')}}</p>
          <starling-logo style="max-width:85px;"/>
        </v-flex>
      </v-layout>
    </v-container>
  </sm-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';
import BadgePopover from '@/views/components/utilities/badge-popover.vue';
import StarlingLogo from '@/views/images/starling-logo';
import SmNavigationDrawer from '@/views/components/sm-navigation-drawer.vue';

export default {
  name: 'sideNav',
  components: {
    BadgePopover,
    StarlingLogo,
    SmNavigationDrawer,
  },
  data() {
    return {
      visible: this.$vuetify.breakpoint.mdAndUp,
      hideNavRoutes: [ 'onboarding' ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    menuItems() {
      return this.$t('app.navigation.menu').filter(item => !!item.desktop);
    },
    commentStatuses() {
      return this.$store.getters['comments/getCommentsStatuses'];
    },
    showCommunityBadge() {
      return this.commentStatuses?.some(status => !status.read && !status.hidden);
    },
    ...mapState({
      partner: state => {
        return state.identity;
      },
      showBackButton: state => {
        return state.navigation.top.showBackButton;
      },
    }),
    backgroundUrl() {
      return this.partner?.theme?.images?.SIDE_NAV?.imageUrl || '/svg/bg-sidenav.svg';
    },
    visibleCallout() {
      return this.$store.getters['home/visibleCallout'];
    },
    showPopover() {
      return this.visibleCallout?.identifier === this.id;
    },
  },
  watch: {
    '$route'(route) {
      if (this.shouldHideSidebar(route.name)) this.visible = false;
    },
    '$i18n.locale'() {
      this.loadCallout();
    },
    'user'() {
      this.loadCallout();
    },
  },
  mounted() {
    // hides sidenav component for any route in hideNavRoutes array
    // set event listeners to hide side nav on request

    if (this.shouldHideSidebar(this.$route.name)) this.visible = false;

    this.loadCallout();

    this.$root.$on('hideSideNav', () => {
      this.visible = false;
    });
    this.$root.$on('showSideNav', () => {
      this.visible = true;
    });
  },
  methods: {
    goToRoute(path) {
      this.$router.push('/' + path);
    },
    loadCallout() {
      this.$store.dispatch('home/getCallout').then(res => {
        this.$store.dispatch('home/getPopoverConfig', this.user.id);
      });
    },
    shouldHideSidebar(routeName) {
      return this.hideNavRoutes.includes(routeName);
    },
    logout() {
      this.$store.dispatch('identity/logout').then(res => {
        this.$router.push({ name: 'presentation' });
      });
    },
    onClosePopover(popover) {
      if (!popover) return;
      const popoverRef = this.$refs[popover];
      if (popoverRef) popoverRef[0].onClosePopover();
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/src/variables.scss';

.side-nav {
  width: $sidebar-width;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  color: $pure-white;
  box-shadow: 0 5px 5px -1px rgba(0, 0, 0, 0.04), 0 6px 8px 0 rgba(0, 0, 0, 0.02), 0 3px 14px 0 rgba(0, 0, 0, 0.04);

  .menu-container {
    .v-icon {
      color: $pure-white;
    }

    .menu-item {
      transition: all 0.2s;
      padding: 20px 40px;
      white-space: nowrap;
      font-size: 16px;
      user-select: none;

      span {
        max-width: 250px;
        overflow: hidden;
        text-transform: capitalize;
        text-overflow: ellipsis;
      }

      i {
        margin-right: 10px;
      }

      &:hover {
        transform: scale(1.05);
        cursor: pointer;
      }
    }

    .powered-by {
      padding: 20px 40px;
      width: 100%;
      white-space: nowrap;
      user-select: none;
    }

    .router-link-exact-active,
    .router-link-active {
      .v-icon {
        color: $pure-white;
      }

      span {
        color: $pure-white;
        font-weight: bolder !important;
      }
    }

    .top-spacer {
      max-height: 100px;
    }

    &.mobile {
      .top-spacer {
        max-height: 140px;
      }
      .menu-item {
        padding: 15px 40px;
      }
    }
  }

  .v-toolbar {
    height: 127px;

    .v-toolbar__content {
      padding-left: 10px;
    }

    .v-toolbar__title {
      margin: 60px auto 0;
      text-align: left;

      h2,
      small {
        color: $font-grey;
        font-weight: 400;
      }

      h2 {
        max-width: 190px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
      }

      span {
        margin-left: 10px;
        text-align: left;
      }
    }
  }

  &.closed {
    width: 0;
    overflow: hidden;
  }

  svg {
    margin-bottom: -10px;
    padding-top: 5px;
    width: 220px;
  }

  .v-avatar {
    margin: 0 5px;
    border: 1px solid $grey;
  }
}
</style>
