var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { lg6: "" } },
            [
              _c("starling-avatar-input", {
                model: {
                  value: _vm.image,
                  callback: function($$v) {
                    _vm.image = $$v
                  },
                  expression: "image"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { lg6: "" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("assessment-history-wrapper", {
                            attrs: { part: {} }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("assessment-history-wrapper", {
                            attrs: { part: { assessmentName: "ENERGY" } }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("assessment-history-wrapper", {
                            attrs: { part: { assessmentName: "CD-RISC2" } }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("assessment-history-wrapper", {
                            attrs: { part: { assessmentName: "STRESS" } }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("assessment-history-wrapper", {
                            attrs: { part: { assessmentName: "PHQ9" } }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("assessment-history-wrapper", {
                            attrs: { part: { assessmentName: "GAD7" } }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-5" }),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { lg6: "" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("element-styled-content", {
                            attrs: {
                              content: [
                                {
                                  key: "h1",
                                  content: "This is a test H1"
                                },
                                {
                                  key: "h2",
                                  content: "This is a test h2"
                                },
                                {
                                  key: "h3",
                                  content: "This is a test h3"
                                },
                                {
                                  key: "h4",
                                  content: "This is a test h4"
                                },
                                {
                                  key: "body",
                                  content: "This is a test body"
                                },
                                {
                                  key: "instructional",
                                  content: "This is a test instructional"
                                },
                                {
                                  key: "mm",
                                  content: "This is a Mini Micky test"
                                },
                                {
                                  key: "mj",
                                  content: "This is a Mini Jenny test"
                                }
                              ]
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-5" }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("element-decision", {
            attrs: {
              "therapy-content": {
                choices: [{ id: 1, selected: true, content: "test" }]
              }
            }
          })
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-5" }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                {
                  staticStyle: { "overflow-x": "auto" },
                  attrs: { xs12: "", "mb-5": "" }
                },
                [
                  _c(
                    "custom-carousel",
                    {
                      attrs: { pagination: _vm.pagination },
                      on: {
                        next: function($event) {
                          _vm.pagination.currentPage++
                        },
                        previous: function($event) {
                          _vm.pagination.currentPage--
                        }
                      }
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "blue lighten-3",
                          staticStyle: { height: "250px" },
                          attrs: {
                            row: "",
                            wrap: "",
                            "align-center": "",
                            "justify-center": ""
                          }
                        },
                        [
                          _c("h3", [
                            _vm._v(
                              _vm._s("Page: " + _vm.pagination.currentPage)
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs4: "" } },
                [
                  _c("v-checkbox", {
                    attrs: { label: "More Pages" },
                    model: {
                      value: _vm.pagination.showMore,
                      callback: function($$v) {
                        _vm.$set(_vm.pagination, "showMore", $$v)
                      },
                      expression: "pagination.showMore"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs4: "" } },
                [
                  _c("v-checkbox", {
                    attrs: { label: "Current Page Completed" },
                    model: {
                      value: _vm.pagination.currentCompleted,
                      callback: function($$v) {
                        _vm.$set(_vm.pagination, "currentCompleted", $$v)
                      },
                      expression: "pagination.currentCompleted"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs4: "" } },
                [
                  _c("v-checkbox", {
                    attrs: { label: "Loading" },
                    model: {
                      value: _vm.pagination.loading,
                      callback: function($$v) {
                        _vm.$set(_vm.pagination, "loading", $$v)
                      },
                      expression: "pagination.loading"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { grow: "", sm11: "" } },
                        [
                          _c("v-slider", {
                            attrs: {
                              min: 0,
                              max: _vm.pagination.totalPages || 30,
                              label: "Current Page",
                              "thumb-size": 24,
                              "thumb-label": "always"
                            },
                            model: {
                              value: _vm.pagination.currentPage,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.pagination,
                                  "currentPage",
                                  _vm._n($$v)
                                )
                              },
                              expression: "pagination.currentPage"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { shrink: "", sm1: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", round: "", color: "error" },
                              on: {
                                click: function($event) {
                                  _vm.pagination.currentPage = null
                                }
                              }
                            },
                            [_vm._v("Reset")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { grow: "", sm11: "" } },
                        [
                          _c("v-slider", {
                            attrs: {
                              min: 0,
                              max: 30,
                              label: "Total Pages",
                              "thumb-size": 24,
                              "thumb-label": "always"
                            },
                            model: {
                              value: _vm.pagination.totalPages,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.pagination,
                                  "totalPages",
                                  _vm._n($$v)
                                )
                              },
                              expression: "pagination.totalPages"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { shrink: "", sm1: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", round: "", color: "error" },
                              on: {
                                click: function($event) {
                                  _vm.pagination.totalPages = null
                                }
                              }
                            },
                            [_vm._v("Reset")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { grow: "", sm11: "" } },
                        [
                          _c("v-slider", {
                            attrs: {
                              min: 0,
                              max: 30,
                              label: "Visible Pages",
                              "thumb-size": 24,
                              "thumb-label": "always"
                            },
                            model: {
                              value: _vm.pagination.totalVisible,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.pagination,
                                  "totalVisible",
                                  _vm._n($$v)
                                )
                              },
                              expression: "pagination.totalVisible"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { shrink: "", sm1: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", round: "", color: "error" },
                              on: {
                                click: function($event) {
                                  _vm.pagination.totalVisible = null
                                }
                              }
                            },
                            [_vm._v("Reset")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-5" }),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { lg6: "" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md6: "" } },
                        [
                          _c("checkup-reminder", {
                            attrs: { value: _vm.checkup }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs4: "" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: { label: "Activate" },
                                    model: {
                                      value: _vm.checkupActive,
                                      callback: function($$v) {
                                        _vm.checkupActive = $$v
                                      },
                                      expression: "checkupActive"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs8: "" } },
                                [
                                  _vm.checkup
                                    ? _c("v-slider", {
                                        attrs: {
                                          min: 0,
                                          max: _vm.checkup.totalDays,
                                          label: "Remaining Days",
                                          "thumb-label": ""
                                        },
                                        model: {
                                          value: _vm.checkup.remainingDays,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.checkup,
                                              "remainingDays",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "checkup.remainingDays"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-5" }),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { lg6: "" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md6: "" } },
                        [
                          _c("depression-meter", {
                            attrs: { value: _vm.depression }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs4: "" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: { label: "Activate" },
                                    model: {
                                      value: _vm.depressionActive,
                                      callback: function($$v) {
                                        _vm.depressionActive = $$v
                                      },
                                      expression: "depressionActive"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs8: "" } },
                                [
                                  _vm.depression !== null
                                    ? _c("v-slider", {
                                        attrs: {
                                          min: 0,
                                          max: 27,
                                          label: "Value",
                                          "thumb-label": ""
                                        },
                                        model: {
                                          value: _vm.depression,
                                          callback: function($$v) {
                                            _vm.depression = _vm._n($$v)
                                          },
                                          expression: "depression"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-5" }),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { lg6: "" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md6: "" } },
                        [
                          _c("anxiety-meter", { attrs: { value: _vm.anxiety } })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs4: "" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: { label: "Activate" },
                                    model: {
                                      value: _vm.anxietyActive,
                                      callback: function($$v) {
                                        _vm.anxietyActive = $$v
                                      },
                                      expression: "anxietyActive"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs8: "" } },
                                [
                                  _vm.anxiety !== null
                                    ? _c("v-slider", {
                                        attrs: {
                                          min: 0,
                                          max: 21,
                                          label: "Value",
                                          "thumb-label": ""
                                        },
                                        model: {
                                          value: _vm.anxiety,
                                          callback: function($$v) {
                                            _vm.anxiety = _vm._n($$v)
                                          },
                                          expression: "anxiety"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-5" }),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { lg6: "" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md6: "" } },
                        [
                          _c("resilience-meter", {
                            attrs: { value: _vm.resilience }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs4: "" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: { label: "Activate" },
                                    model: {
                                      value: _vm.resilienceActive,
                                      callback: function($$v) {
                                        _vm.resilienceActive = $$v
                                      },
                                      expression: "resilienceActive"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs8: "" } },
                                [
                                  _vm.resilience !== null
                                    ? _c("v-slider", {
                                        attrs: {
                                          min: 0,
                                          max: 8,
                                          label: "Value",
                                          "thumb-label": ""
                                        },
                                        model: {
                                          value: _vm.resilience.value,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.resilience,
                                              "value",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "resilience.value"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-5" }),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { lg6: "" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md6: "" } },
                        [
                          _c("battery", {
                            attrs: {
                              level: _vm.battery.value,
                              "number-of-days": _vm.battery.numberOfDays,
                              "hide-battery-text": _vm.battery.hideText,
                              allowSelection: !_vm.batteryReadOnly
                            },
                            on: {
                              selected: function($event) {
                                _vm.battery.value = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs4: "" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: { label: "Activate" },
                                    model: {
                                      value: _vm.batteryActive,
                                      callback: function($$v) {
                                        _vm.batteryActive = $$v
                                      },
                                      expression: "batteryActive"
                                    }
                                  }),
                                  _c("v-checkbox", {
                                    attrs: { label: "Read-only" },
                                    model: {
                                      value: _vm.batteryReadOnly,
                                      callback: function($$v) {
                                        _vm.batteryReadOnly = $$v
                                      },
                                      expression: "batteryReadOnly"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs8: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _vm.battery !== null &&
                                          _vm.battery.value !== null
                                            ? _c("v-slider", {
                                                attrs: {
                                                  min: 0,
                                                  max: 100,
                                                  label: "Battery Level",
                                                  "thumb-label": ""
                                                },
                                                model: {
                                                  value: _vm.battery.value,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.battery,
                                                      "value",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression: "battery.value"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _vm.battery !== null
                                            ? _c("v-slider", {
                                                attrs: {
                                                  min: 0,
                                                  max: 10,
                                                  label: "Number of days",
                                                  "thumb-label": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.battery.numberOfDays,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.battery,
                                                      "numberOfDays",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "battery.numberOfDays"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _vm.battery !== null
                                            ? _c("v-checkbox", {
                                                attrs: { label: "Hide text" },
                                                model: {
                                                  value: _vm.battery.hideText,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.battery,
                                                      "hideText",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "battery.hideText"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-5" }),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { lg6: "" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "pa-4", attrs: { sm12: "" } },
                        [_c("slider", { attrs: { min: 1, max: 10 } })],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-4", attrs: { sm12: "" } },
                        [
                          _c("slider", {
                            attrs: { min: 1, max: 10, color: "grey" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-4", attrs: { sm12: "" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 8, color: "grey" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-4", attrs: { sm12: "" } },
                        [
                          _c("slider", {
                            attrs: { min: 4, max: 6, color: "grey" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-4", attrs: { sm12: "" } },
                        [
                          _c("slider", {
                            attrs: { min: 10, max: 30, color: "grey" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-4", attrs: { sm12: "" } },
                        [
                          _c("slider", {
                            attrs: {
                              value: 6,
                              min: 0,
                              max: 10,
                              color: "primary"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-4", attrs: { sm12: "" } },
                        [
                          _c("slider", {
                            attrs: {
                              value: 6,
                              min: 0,
                              max: 10,
                              color: "orange"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-4", attrs: { sm12: "" } },
                        [
                          _c("slider", {
                            attrs: { value: 6, min: 0, max: 10, color: "red" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-4", attrs: { sm12: "" } },
                        [
                          _c("slider", {
                            attrs: {
                              value: 6,
                              min: 0,
                              max: 10,
                              color: "purple"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-5" }),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        _vm._l(_vm.availableIcons, function(icon) {
          return _c(
            "v-flex",
            { key: icon, attrs: { lg2: "" } },
            [
              _c("v-icon", {
                attrs: { size: "25px" },
                domProps: { textContent: _vm._s("$vuetify.icons." + icon) }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }