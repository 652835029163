<template>
  <v-container fluid>

    <v-layout row wrap>
      <v-flex lg6>
        <starling-avatar-input v-model="image"/>
      </v-flex>
    </v-layout>

    <v-layout row wrap>
      <v-flex lg6>
        <v-container fluid>
          <v-layout row wrap>
            <v-flex>
              <assessment-history-wrapper :part="{}"/>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex>
              <assessment-history-wrapper :part="{assessmentName:'ENERGY'}"/>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex>
              <assessment-history-wrapper :part="{assessmentName:'CD-RISC2'}"/>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex>
              <assessment-history-wrapper :part="{assessmentName:'STRESS'}"/>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex>
              <assessment-history-wrapper :part="{assessmentName:'PHQ9'}"/>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex>
              <assessment-history-wrapper :part="{assessmentName:'GAD7'}"/>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-divider class="my-5"></v-divider>

    <v-layout row wrap>
      <v-flex lg6>
        <v-container fluid>
          <v-layout row wrap>
            <v-flex>
              <element-styled-content :content="[{
          key:'h1',
          content:'This is a test H1'
        },{
          key:'h2',
          content:'This is a test h2'
        },{
          key:'h3',
          content:'This is a test h3'
        },{
          key:'h4',
          content:'This is a test h4'
        },{
          key:'body',
          content:'This is a test body'
        },{
          key:'instructional',
          content:'This is a test instructional'
        },{
          key:'mm',
          content:'This is a Mini Micky test'
        },{
          key:'mj',
          content:'This is a Mini Jenny test'
        }]">
              </element-styled-content>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
    <v-divider class="my-5"></v-divider>

    <v-container fluid>
      <element-decision :therapy-content="{choices:[{id:1, selected:true, content:'test' }]}"></element-decision>
    </v-container>

    <v-divider class="my-5"></v-divider>

    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12 mb-5 style="overflow-x: auto">
          <custom-carousel
              :pagination="pagination"
              @next="pagination.currentPage++"
              @previous="pagination.currentPage--">
            <v-layout row wrap align-center justify-center style="height:250px" class="blue lighten-3">
              <h3>{{ `Page: ${pagination.currentPage}` }}</h3>
            </v-layout>
          </custom-carousel>
        </v-flex>
        <v-flex xs4>
          <v-checkbox v-model="pagination.showMore" label="More Pages"></v-checkbox>
        </v-flex>
        <v-flex xs4>
          <v-checkbox v-model="pagination.currentCompleted" label="Current Page Completed"></v-checkbox>
        </v-flex>
        <v-flex xs4>
          <v-checkbox v-model="pagination.loading" label="Loading"></v-checkbox>
        </v-flex>
        <v-flex xs12>
          <v-layout row wrap>
            <v-flex grow sm11>
              <v-slider :min="0" :max="pagination.totalPages || 30" v-model.number="pagination.currentPage" label="Current Page" :thumb-size="24" thumb-label="always"></v-slider>
            </v-flex>
            <v-flex shrink sm1>
              <v-btn small round color="error" @click="pagination.currentPage = null">Reset</v-btn>
            </v-flex>

            <v-flex grow sm11>
              <v-slider :min="0" :max="30" v-model.number="pagination.totalPages" label="Total Pages" :thumb-size="24" thumb-label="always"></v-slider>
            </v-flex>
            <v-flex shrink sm1>
              <v-btn small round color="error" @click="pagination.totalPages = null">Reset</v-btn>
            </v-flex>

            <v-flex grow sm11>
              <v-slider :min="0" :max="30" v-model.number="pagination.totalVisible" label="Visible Pages" :thumb-size="24" thumb-label="always"></v-slider>
            </v-flex>
            <v-flex shrink sm1>
              <v-btn small round color="error" @click="pagination.totalVisible = null">Reset</v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>

    <v-divider class="my-5"></v-divider>

    <v-layout row wrap>
      <v-flex lg6>
        <v-container fluid>
          <v-layout row wrap>
            <v-flex xs12 md6>
              <checkup-reminder :value="checkup"></checkup-reminder>
            </v-flex>
            <v-flex xs12>
              <v-layout>
                <v-flex xs4>
                  <v-checkbox v-model="checkupActive" label="Activate"></v-checkbox>
                </v-flex>
                <v-flex xs8>
                  <v-slider v-if="checkup" :min="0" :max="checkup.totalDays" v-model.number="checkup.remainingDays" label="Remaining Days" thumb-label></v-slider>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-divider class="my-5"></v-divider>

    <v-layout row wrap>
      <v-flex lg6>
        <v-container fluid>
          <v-layout row wrap>
            <v-flex xs12 md6>
              <depression-meter :value="depression"></depression-meter>
            </v-flex>
            <v-flex xs12>
              <v-layout>
                <v-flex xs4>
                  <v-checkbox v-model="depressionActive" label="Activate"></v-checkbox>
                </v-flex>
                <v-flex xs8>
                  <v-slider v-if="depression !== null" :min="0" :max="27" v-model.number="depression" label="Value" thumb-label></v-slider>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-divider class="my-5"></v-divider>

    <v-layout row wrap>
      <v-flex lg6>
        <v-container fluid>
          <v-layout row wrap>
            <v-flex xs12 md6>
              <anxiety-meter :value="anxiety"></anxiety-meter>
            </v-flex>
            <v-flex xs12>
              <v-layout>
                <v-flex xs4>
                  <v-checkbox v-model="anxietyActive" label="Activate"></v-checkbox>
                </v-flex>
                <v-flex xs8>
                  <v-slider v-if="anxiety !== null" :min="0" :max="21" v-model.number="anxiety" label="Value" thumb-label></v-slider>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-divider class="my-5"></v-divider>

    <v-layout row wrap>
      <v-flex lg6>
        <v-container fluid>
          <v-layout row wrap>
            <v-flex xs12 md6>
              <resilience-meter :value="resilience"></resilience-meter>
            </v-flex>
            <v-flex xs12>
              <v-layout>
                <v-flex xs4>
                  <v-checkbox v-model="resilienceActive" label="Activate"></v-checkbox>
                </v-flex>
                <v-flex xs8>
                  <v-slider v-if="resilience !== null" :min="0" :max="8" v-model.number="resilience.value" label="Value" thumb-label></v-slider>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-divider class="my-5"></v-divider>

    <v-layout row wrap>
      <v-flex lg6>
        <v-container fluid>
          <v-layout row wrap>
            <v-flex xs12 md6>
              <battery :level="battery.value" :number-of-days="battery.numberOfDays" :hide-battery-text="battery.hideText" :allowSelection="!batteryReadOnly" @selected="battery.value = $event"></battery>
            </v-flex>
            <v-flex xs12>
              <v-layout>
                <v-flex xs4>
                  <v-checkbox v-model="batteryActive" label="Activate"></v-checkbox>
                  <v-checkbox v-model="batteryReadOnly" label="Read-only"></v-checkbox>
                </v-flex>
                <v-flex xs8>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-slider v-if="battery !== null && battery.value !== null" :min="0" :max="100" v-model.number="battery.value" label="Battery Level" thumb-label></v-slider>
                    </v-flex>
                    <v-flex xs12>
                      <v-slider v-if="battery !== null" :min="0" :max="10" v-model.number="battery.numberOfDays" label="Number of days" thumb-label></v-slider>
                    </v-flex>
                    <v-flex xs12>
                      <v-checkbox v-if="battery !== null" v-model="battery.hideText" label="Hide text"></v-checkbox>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-divider class="my-5"></v-divider>

    <v-layout row wrap>
      <v-flex lg6>
        <v-container fluid>
          <v-layout row wrap>
            <v-flex sm12 class="pa-4">
              <slider :min="1"
                      :max="10"
              ></slider>
            </v-flex>
            <v-flex sm12 class="pa-4">
              <slider :min="1" :max="10" color="grey"></slider>
            </v-flex>
            <v-flex sm12 class="pa-4">
              <slider :min="0" :max="8" color="grey"></slider>
            </v-flex>
            <v-flex sm12 class="pa-4">
              <slider :min="4" :max="6" color="grey"></slider>
            </v-flex>
            <v-flex sm12 class="pa-4">
              <slider :min="10" :max="30" color="grey"></slider>
            </v-flex>
            <v-flex sm12 class="pa-4">
              <slider :value="6" :min="0" :max="10" color="primary"></slider>
            </v-flex>
            <v-flex sm12 class="pa-4">
              <slider :value="6" :min="0" :max="10" color="orange"></slider>
            </v-flex>
            <v-flex sm12 class="pa-4">
              <slider :value="6" :min="0" :max="10" color="red"></slider>
            </v-flex>
            <v-flex sm12 class="pa-4">
              <slider :value="6" :min="0" :max="10" color="purple"></slider>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-divider class="my-5"></v-divider>
    <v-layout row wrap>
      <v-flex lg2 v-for="icon in availableIcons" :key="icon">
        <v-icon size="25px" v-text="'$vuetify.icons.'+icon"></v-icon>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import Battery from './home/components/battery';
import ElementStyledContent from './products/components/element-styled-content';
import AnxietyMeter from './home/components/anxiety-meter';
import DepressionMeter from './home/components/depression-meter';
import Slider from './components/slider';
import CheckupReminder from './home/components/checkup-reminder';
import ResilienceMeter from './home/components/resilience-meter';
import customCarousel from '@/views/components/utilities/custom-carousel';
import AssessmentHistoryWrapper from '@/views/home/components/assessment-history-wrapper';
import ElementDecision from '@/views/products/components/elements/element-decision';
import StarlingAvatarInput from '@/views/avatar/starling-avatar-input.vue';

export default {
  name: 'Testing',
  components: {
    StarlingAvatarInput,
    ElementDecision,
    AssessmentHistoryWrapper,
    ResilienceMeter,
    CheckupReminder,
    Slider,
    DepressionMeter,
    AnxietyMeter,
    ElementStyledContent,
    Battery,
    customCarousel,
  },
  data() {
    return {
      image: null,
      checkupActive: true,
      checkup: { remainingDays: 21, totalDays: 21 },
      depressionActive: true,
      depression: 5,
      anxietyActive: true,
      anxiety: 5,
      resilienceActive: true,
      resilience: { value: 4, min: 0, max: 8 },
      batteryActive: true,
      batteryReadOnly: true,
      battery: { value: 50, numberOfDays: 0, hideText: false },
      pagination: {
        currentPage: 1,
        totalPages: 10,
        totalVisible: 10,
        currentCompleted: false,
        showMore: true,
        loading: false,
      },
    };
  },
  computed: {
    availableIcons() {
      return Object.keys(this.$vuetify.icons).filter(k => k.startsWith('starling'));
    },
  },
  watch: {
    checkupActive(activated) {
      if (activated) {
        this.checkup = { remainingDays: 21, totalDays: 21 };
      } else {
        this.checkup = null;
      }
    },
    depressionActive(activated) {
      if (activated) {
        this.depression = 8;
      } else {
        this.depression = null;
      }
    },
    anxietyActive(activated) {
      if (activated) {
        this.anxiety = 8;
      } else {
        this.anxiety = null;
      }
    },
    resilienceActive(activated) {
      if (activated) {
        this.resilience = { value: 4, min: 0, max: 8 };
      } else {
        this.resilience = null;
      }
    },
    batteryActive(activated) {
      if (activated) {
        this.battery.value = 50;
      } else {
        this.battery.value = null;
      }
    },
    nextPage() {
      if (this.page < this.totalPages) {
        this.page++;
      }
    },
    previousPage() {
      if (this.page > 1) {
        this.page--;
      }
    },
  },
  mounted() {
    const items = [
      {
        completed: '2021-08-19T18:56:23.702Z',
        assessmentId: 23,
        assessmentName: 'PHQ9',
        scoreName: 'HL',
        scoreDescription: 'Healthy: Low Average',
        scoreOrder: 3,
        simplifiedScoreName: 'VL',
        simplifiedScoreDescription: 'Very low',
        simplifiedScoreOrder: 4,
      },
      {
        completed: '2021-08-19T18:56:29.557Z',
        assessmentId: 24,
        assessmentName: 'GAD7',
        scoreName: 'HL',
        scoreDescription: 'Healthy: Low Average',
        scoreOrder: 3,
        simplifiedScoreName: 'VL',
        simplifiedScoreDescription: 'Very low',
        simplifiedScoreOrder: 4,
      },
      {
        completed: '2021-07-12T20:30:15.268Z',
        assessmentId: 2,
        assessmentName: 'STRESS',
        scoreName: 'MO',
        scoreDescription: 'Moderate',
        scoreOrder: 6,
        simplifiedScoreName: 'HI',
        simplifiedScoreDescription: 'High',
        simplifiedScoreOrder: 1,
      },
      {
        completed: '2021-07-12T20:30:30.565Z',
        assessmentId: 4,
        assessmentName: 'ENERGY',
        scoreName: 'HL',
        scoreDescription: 'Healthy: Low Average',
        scoreOrder: 3,
        simplifiedScoreName: 'MD',
        simplifiedScoreDescription: 'Moderate',
        simplifiedScoreOrder: 2,
      },
      {
        completed: '2021-07-12T20:30:36.230Z',
        assessmentId: 6,
        assessmentName: 'CD-RISC2',
        scoreName: 'SE',
        scoreDescription: 'Severe',
        scoreOrder: 7,
        simplifiedScoreName: 'VL',
        simplifiedScoreDescription: 'Very low',
        simplifiedScoreOrder: 4,
      },
      {
        completed: '2021-08-16T19:54:08.023Z',
        assessmentId: 8,
        assessmentName: 'STRESS',
        scoreName: 'MO',
        scoreDescription: 'Moderate',
        scoreOrder: 6,
        simplifiedScoreName: 'MD',
        simplifiedScoreDescription: 'Moderate',
        simplifiedScoreOrder: 2,
      },
      {
        completed: '2021-08-16T19:54:18.965Z',
        assessmentId: 10,
        assessmentName: 'ENERGY',
        scoreName: 'HL',
        scoreDescription: 'Healthy: Low Average',
        scoreOrder: 3,
        simplifiedScoreName: 'MD',
        simplifiedScoreDescription: 'Moderate',
        simplifiedScoreOrder: 2,
      },
      {
        completed: '2021-08-16T19:54:24.071Z',
        assessmentId: 12,
        assessmentName: 'CD-RISC2',
        scoreName: 'SE',
        scoreDescription: 'Severe',
        scoreOrder: 7,
        simplifiedScoreName: 'VL',
        simplifiedScoreDescription: 'Very low',
        simplifiedScoreOrder: 4,
      },
      {
        completed: '2021-08-16T19:56:04.244Z',
        assessmentId: 13,
        assessmentName: 'PHQ9',
        scoreName: 'HH',
        scoreDescription: 'Healthy: High Average',
        scoreOrder: 1,
        simplifiedScoreName: 'VL',
        simplifiedScoreDescription: 'Very low',
        simplifiedScoreOrder: 4,
      },
      {
        completed: '2021-08-16T20:00:30.663Z',
        assessmentId: 14,
        assessmentName: 'GAD7',
        scoreName: 'HH',
        scoreDescription: 'Healthy: High Average',
        scoreOrder: 1,
        simplifiedScoreName: 'VL',
        simplifiedScoreDescription: 'Very low',
        simplifiedScoreOrder: 4,
      },
      {
        completed: '2021-08-19T18:56:02.318Z',
        assessmentId: 18,
        assessmentName: 'STRESS',
        scoreName: 'ML',
        scoreDescription: 'Mild',
        scoreOrder: 5,
        simplifiedScoreName: 'MD',
        simplifiedScoreDescription: 'Moderate',
        simplifiedScoreOrder: 2,
      },
      {
        completed: '2021-08-19T18:56:09.512Z',
        assessmentId: 20,
        assessmentName: 'ENERGY',
        scoreName: 'HA',
        scoreDescription: 'Healthy: Average',
        scoreOrder: 2,
        simplifiedScoreName: 'HI',
        simplifiedScoreDescription: 'High',
        simplifiedScoreOrder: 1,
      },
      {
        completed: '2021-08-19T18:56:15.030Z',
        assessmentId: 22,
        assessmentName: 'CD-RISC2',
        scoreName: 'MO',
        scoreDescription: 'Moderate',
        scoreOrder: 6,
        simplifiedScoreName: 'LO',
        simplifiedScoreDescription: 'Low',
        simplifiedScoreOrder: 3,
      },
    ];

    const chart = [];

    const scores = {
      1: 7,
      2: 6,
      3: 5,
      4: 4,
      5: 3,
      6: 2,
      7: 1,
    };

    items &&
    items.forEach((item) => {
      const assessment = {
        GAD7: item.assessmentName === 'GAD7' ? scores[item.scoreOrder] : 0,
        PHQ9: item.assessmentName === 'PHQ9' ? scores[item.scoreOrder] : 0,
        STRESS: item.assessmentName === 'STRESS' ? scores[item.scoreOrder] : 0,
        ENERGY: item.assessmentName === 'ENERGY' ? scores[item.scoreOrder] : 0,
        'CD-RISC2': item.assessmentName === 'CD-RISC2' ? scores[item.scoreOrder] : 0,
      };

      chart.push({
        timestamp: item.completed,
        assessment,
      });
    });

    this.assessmentHistory = chart;
  },
};
</script>

<style scoped>
.v-card.product-player-card {
  border-radius: 15px;
}
</style>
